<div class="w-screen loading-container">
    <div class="text-gray-third">
        <span *ngIf="!active">Please wait</span>
        <span *ngIf="active">Tada!</span>
    </div>

    <!-- CAT -->
    <div class="mt-6" style="min-height: 100px;">
        <img *ngIf="!active" src="assets/images/cat-eye-closed.svg" alt="cat_image_1">
        <img *ngIf="active" src="assets/images/cat-eye-opened.svg" alt="cat_image_2">
    </div>

    <div class="mt-6 text-gray-third">
        <div *ngIf="!active">We're loading your <br> payment session...</div>
        <div *ngIf="active">Your payment session <br> starts now</div>
    </div>
    <div class="mt-4 width flex justify-content-center">
        <p-progressBar mode="indeterminate" [style]="{ height: '12px', width: '200px' }"></p-progressBar>
    </div>
</div>