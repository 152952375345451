<div class="p-4 bg-light">
    <div class="flex justify-content-center align-items-center">
        <div *ngIf="state === 'confirm'">
            <div class="flex text-2xl">
                <div class="text-gray mx-2"> Total </div>
                <div class="tex-dark-blue mx-2">
                    <currency [amount]="totalAmount"></currency>
                </div>
            </div>
        </div>

        <ng-container *ngIf="state === 'loading'">
            <div>
                {{ message }}
                <div class="flex justify-content-center">
                    <p-progressSpinner styleClass="w-4rem h-4rem"></p-progressSpinner>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="state === 'acknowledgement'">
            <div [innerHTML]="message"></div>
        </ng-container>
    </div>
</div>

<div class="flex justify-content-center py-4 p-dialog-footer text-sm" *ngIf="state === 'confirm'">
    <div class="text-gray cursor-pointer mx-2" (click)="dialogRef.close()"> Dismiss </div>
    <div class="cursor-pointer mx-2 text-blue">
        <div *ngIf="paymentMethod === 'card'" (click)="chargeCard()">Pay with card</div>
        <div *ngIf="paymentMethod === 'google-pay'">Pay with <img class="ml-3" src="assets/images/g-pay.svg" alt="card_image"></div>
        <div *ngIf="paymentMethod === 'apple-pay'">Pay with <img class="ml-3" src="assets/images/apple-pay.svg" alt="card_image"></div>
    </div>
</div>

<div class="flex justify-content-center py-4 p-dialog-footer text-sm" *ngIf="state === 'acknowledgement'">
    <div class="text-gray cursor-pointer mx-2" (click)="onReload()"> Dismiss </div>
</div>