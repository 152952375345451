import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject, map } from 'rxjs';
import { environment } from 'src/environments/environment.development';
import { UtilsService } from '../utils/utils.service';

enum APIEnum {
  GET_FARE_BREAKDOWN = 'get_fare_breakdown',
  CHARGE_CARD = 'charge_card',
  CHARGE_APPLE_OR_GPAY = 'charge_apple_or_gpay',
  GET_TRIP_DETAIL = 'get_trip_details',
  EXTEND_PAYMENT_TIME = 'extend_payment_time',
}

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  triggerSubmitPayment: Subject<void> = new Subject<void>();


  constructor(private http: HttpClient, private rotuer: Router, private utils: UtilsService) { }
  token!: string;
  charge_card ( payload: { token?: string, amount: number, nounce: string, is_save_card?: number, card_type?: number, offset?: number, email?: string }) {
    payload.offset = new Date().getTimezoneOffset();
    payload.token = this.token;
    payload.card_type = 52;
    
    return this.http.post(environment.baseUrl + APIEnum.CHARGE_CARD, payload );
  }

  charge_gpay_or_applepay ( payload: { token?: string, amount: number, sourceToken: string, offset?: number, email?: string, is_apple_pay?: number }) {
    payload.offset = new Date().getTimezoneOffset();
    payload.token = this.token;
    
    return this.http.post(environment.baseUrl + APIEnum.CHARGE_APPLE_OR_GPAY, payload );
  }

  extend_payment_time () {
    return this.http.post(environment.baseUrl + APIEnum.EXTEND_PAYMENT_TIME, {token: this.token});
  }

  expireSession () {
    this.token = '';
    this.rotuer.navigate(['/expired']);
  }
}
