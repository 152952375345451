import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  
  alertLimit: number = 30; // seconds

  // Comes from trip_detail api
  allowedTimeInMins: number = 0;
  isTimeIncreased: number = 0;
  linkCreatedAt!: Date;

  timerSubject: Subject<any> = new Subject<any>();
  resetTimerSubject: Subject<void> = new Subject<void>();

  convertSecondsToMinutesSeconds(totalSeconds: number): string {
    if(totalSeconds < 0) totalSeconds = 0;
    const minutes = Math.floor(totalSeconds / 60).toFixed().padStart(2, '0');
    const seconds = (totalSeconds % 60).toFixed().padStart(2, '0');
    return `${minutes}:${seconds}`;
  }

  getRemainingTime(allowedTimeInMin: number = this.allowedTimeInMins, linkCreatedAt: Date = new Date(this.linkCreatedAt)): number {
    const now = new Date();
  
    // Calculate the elapsed time in minutes
    const elapsedTimeInMin = Math.max(
      0,
      (now.getTime() - linkCreatedAt.getTime()) / (1000 * 60)
    );
  
    // Calculate the remaining time in minutes, ensuring it's not negative
    const remainingTimeInMin = Math.max(0, allowedTimeInMin - elapsedTimeInMin);
  
    // Convert remaining time to seconds
    const remainingTimeInSec = remainingTimeInMin * 60;
  
    return Number(remainingTimeInSec.toFixed());
  }

  
  isEmailValid(value: string): boolean {
    const email: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email.test(value)) return false;
    return true;
  }

  preLoadCatImages () {
    const images = ["assets/images/cat-eye-closed.svg","assets/images/cat-eye-opened.svg"];
      for(let i = 0; i < images.length; i++){
        let img = new Image();
        img.src = images[i];
        img.onload = () => {
          console.log("load image ", i + 1);
        }
      }
  }
}
