import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

  constructor(private readonly config: DynamicDialogConfig, public readonly dialogRef: DynamicDialogRef) {}
  menuItems: Array<{title: String, url?: string}> = [
    { title: 'Home', url: 'https://qudos.us/' },
    { title: 'About Us', url: 'https://qudos.us/about/' },
    { title: 'Terms', url: 'https://qudos.us/terms/' },
    { title: 'Privacy', url: 'https://qudos.us/privacy/' },
  ]

  onNavigate (item: any) {
    window.location.href = item?.url;
  }
}
