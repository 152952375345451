import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, map } from 'rxjs';
import { AssignedStatusType } from 'src/app/interface/trip.interface';
import { environment } from 'src/environments/environment.development';
import { PaymentService } from '../payment/payment.service';

enum APIEnum {
  GET_FARE_BREAKDOWN = 'get_fare_breakdown',
  GET_TRIP_DETAIL = 'get_trip_details',
  GET_TRIP_COORDINATES = 'payment/get_trip_coordinates',
}

@Injectable({
  providedIn: 'root'
})
export class TripService {

  constructor(private http: HttpClient, private paymentService: PaymentService) { }


  fareEstimate: BehaviorSubject<any> = new BehaviorSubject<any>({});
  trip: BehaviorSubject<any> = new BehaviorSubject<any>({});
  linkExpired: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  refetchSubject: Subject<{silent: boolean}> = new Subject<{silent: boolean}>();


  get_fare_breakdown () {

    return this.http.post(environment.baseUrl + APIEnum.GET_FARE_BREAKDOWN, { token: this.paymentService.token })
    .pipe(map((breakdown: any) => {
      this.fareEstimate.next(breakdown?.fare_estimate);
      return breakdown;
    }));
  }

  get_trip_detail () {
    
    return this.http.post(environment.baseUrl + APIEnum.GET_TRIP_DETAIL, { token: this.paymentService.token })
    .pipe(map((trip: any) => {
      if(trip?.flag === 6002) {
        this.linkExpired.next(true);
      } else {
        this.trip.next(trip?.trip);

      }
      this.linkExpired.next(false);

      return trip;
    }));
  }

  get_trip_history(payload: { mas_trip_id: any, token: string } | { driver_id: number; start_date: Date | string; end_date: Date | string, token: string }) {
    return this.http.post(environment.baseUrl + APIEnum.GET_TRIP_COORDINATES, payload);
}

  get_assigned_status(trip: any): AssignedStatusType {
    if (trip?.ride_status === 4) {
        return 'completed';
    }

    if (trip?.ride_status === 5 || trip?.ride_status === 11) {
        return 'cancelled';
    }

    if (trip?.ride_status === 7) {
        return 'no show';
    }

    if (trip?.ride_status === 3) {
        return 'dispatched';
    }

    if (!trip?.driver_id && !trip?.declined_driver) {
        return 'pending';
    } else if (trip?.declined_driver) {
        return 'declined';
    } else if (trip?.driver_id) {
        return 'confirmed';
    }
    return 'pending';
  }
}
