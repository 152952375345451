export const environment = {
    environmentLabel: 'dev',
    production: false,
    baseUrl: 'https://cd.ridequdos.com/',
    stripeKey: 'pk_test_x0nIalqvXaliqoYO9Qdcb94Q',
    SocketURL: 'https://socketsdev.ridequdos.com',
    merchantId: "BCR2DN4TZKCPLGDA",
    merchantName: "Qudos Technologies Inc",
    googeMapAPI: 'AIzaSyDVxUnImtx75BtZS3yQJKhh_XgKFbWcKaE'
};
