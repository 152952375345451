<nav class="navbar flex align-items-center text-white">
    <div class="flex align-items-center justify-content-between px-5 w-full">
        <img src="assets/images/logo.png" alt="qudos_logo" class="logo">
        <div class="flex">
            <div>
                Payment Portal
            </div>
            <div class="pl-3 cursor-pointer" (click)="openMenu()">
                <i class="pi pi-bars text-2xl"></i>
            </div>
        </div>
    </div>
</nav>