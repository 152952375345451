import { Component } from '@angular/core';
import { environment } from 'src/environments/environment.development';
import { UtilsService } from './services/utils/utils.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'payment-app-web';
  
  envName!: string;

	constructor(private utils: UtilsService) {
		this.envName = environment.environmentLabel;
    this.utils.preLoadCatImages();
	}
}
