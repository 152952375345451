import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {

  transform(value: number, mode: 'second-to-minute' = 'second-to-minute'): unknown {

    if(mode === 'second-to-minute') {
      if(value < 0) value = 0;
      const hours = Math.floor(value / 3600).toFixed().padStart(2, '0');
      const minutes = Math.floor((value % 3600) / 60).toFixed().padStart(2, '0');
      const seconds = (value % 60).toFixed().padStart(2, '0');

      return `${hours}:${minutes}:${seconds}`;
    }
    return value;
  }

}
