import { NgModule } from '@angular/core';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CallInfoDialogComponent } from './components/call-info-dialog/call-info-dialog.component';
import { ConfirmPaymentDialogComponent } from './components/confirm-payment-dialog/confirm-payment-dialog.component';
import { CurrencyComponent } from '../core/components/currency/currency.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { CommonModule } from '@angular/common';
import { SplashLoadingComponent } from './components/splash-loading/splash-loading.component';
import { ExtendTimeDialogComponent } from './components/extend-time-dialog/extend-time-dialog.component';
import { DividerModule } from 'primeng/divider';
import { TimePipe } from './pipes/time/time.pipe';
import { InputTextModule } from 'primeng/inputtext';

const components: any[] = [
  ConfirmDialogComponent,
  SplashLoadingComponent,
  TimePipe
]

const modules: any[] = [
  ButtonModule,
  ProgressSpinnerModule,
  CurrencyComponent,
  CheckboxModule,
  ProgressBarModule,
  DividerModule,
  InputTextModule
];


@NgModule({
  declarations: [
    ...components,
    CallInfoDialogComponent,
    ConfirmPaymentDialogComponent,
    ExtendTimeDialogComponent,
  ],
  imports: [
    CommonModule,
    ...modules
  ],
  exports: [ ...components, ...modules ]
})
export class SharedModule { }
