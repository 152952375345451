<div class="p-4 bg-light text-center">
    <div>
        <div class="mt-3 text-dark-blue fs-22">
            {{ timeLeft | time }}
        </div>
        <div class="mt-2">
            <img *ngIf="!extended" src="assets/images/cat-eye-closed.svg" alt="cat">
            <img *ngIf="extended" src="assets/images/cat-eye-opened.svg" alt="cat">
        </div>
    
        <div class="text-gray-third fs-13 mt-2 mb-3">
            <ng-container *ngIf="!extended">
                Your session is about to expire, <br> do you want to extend your time
            </ng-container>
            <ng-container *ngIf="extended">
                Your session is extended. Please proceed to complete your payment
            </ng-container>
        </div>
    </div>
</div>

<div class="flex justify-content-center py-4 p-dialog-footer text-sm">
    <ng-container *ngIf="!extended">
        <div class="text-gray cursor-pointer mx-2" [class.disabled]="isLoading" (click)="dialogRef.close()"> Dismiss </div>
        <div class="cursor-pointer mx-2 text-blue" [class.disabled]="isLoading" (click)="extendTime()"> Yes, Please Extend </div>
    </ng-container>

    <ng-container *ngIf="extended">
        <div class="cursor-pointer mx-2 text-blue" (click)="dialogRef.close()"> Ok </div>
    </ng-container>
    
</div>