import { Component } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { MenuComponent } from '../menu/menu.component';

@Component({
  standalone: true,
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  constructor (private dialogService: DialogService) {}

  openMenu () {
    this.dialogService.open(MenuComponent, {
      width: '100%',
      height: '100%',
      showHeader: false
    })
  }
}
