import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PaymentMethod } from 'src/app/interface/payment.interface';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { TripService } from 'src/app/services/trip/trip.service';

@Component({
  selector: 'app-confirm-payment-dialog',
  templateUrl: './confirm-payment-dialog.component.html',
  styleUrls: ['./confirm-payment-dialog.component.scss']
})
export class ConfirmPaymentDialogComponent implements OnInit {

  totalAmount!: number;
  paymentMethod: PaymentMethod = PaymentMethod.CARD;
  cardToken!: string;
  createAccountSaveCard: boolean = false;
  isLoading: boolean = false;
  state: 'confirm' | 'loading' | 'acknowledgement' = 'confirm';
  message!: string;
  email!: string;
  trip: any;

  constructor(private readonly config: DynamicDialogConfig, public readonly dialogRef: DynamicDialogRef, private paymentService: PaymentService, private tripService: TripService) { }

  ngOnInit(): void {
    this.totalAmount = this.config?.data?.totalAmount;
    this.paymentMethod = this.config?.data?.paymentMethod;
    this.cardToken = this.config?.data?.cardToken;
    this.createAccountSaveCard = this.config?.data?.createAccountSaveCard;
    this.email = this.config?.data?.email;
    this.state = this.config?.data?.state || 'confirm';

    this.tripService.trip.subscribe((trip) => {
      this.trip = trip;
    });

    console.log("this.state", this.state);
    if(this.state === 'loading') {
      if(this.paymentMethod === PaymentMethod.GOOGLE_PAY || this.paymentMethod === PaymentMethod.APPLE_PAY) {
        this.onLoading();
        this.charge_gpay_or_applepay();
      }
    }
  }

  onLoading() {
    this.isLoading = true;
    this.state = 'loading';
    this.config.header = 'Now Processing...';
    this.message = 'Do not refresh or close this page to avoid duplicate charges or cancellation';
  }

  onAcknowledgement () {
    this.state = 'acknowledgement';
    this.config.header = 'Thank You!';
    this.message = `You're all set. We sent you a confirmation text. The charge will appear as Qudos Technologies. <br /> <br /> Please install the Qudos rider app and complete registration and the receipt will be email to you. <br /> <br /> Your driver will now provide the car service you need. Thank you for selecting ${this.trip?.client_name || 'NA'}.`;
  }

  chargeCard () {
    this.onLoading();
    this.paymentService.charge_card({
      amount: this.totalAmount,
      nounce: this.cardToken,
      is_save_card: this.createAccountSaveCard ? 1 : 0,
      email: this.email,
    }).subscribe((res: any) => {
      this.state = 'acknowledgement';
      if(res?.error) {
        this.message = res?.error;
        this.config.data = 'Something went wrong!';
      } else {
        this.onAcknowledgement();
      }
    }, (err) => {
      this.state = 'acknowledgement';
      this.message = err?.message;
      this.config.data = 'Something went wrong!';
    });
  }

  charge_gpay_or_applepay () {
    this.paymentService.charge_gpay_or_applepay({
      amount: Number(this.totalAmount.toFixed(2)),
      sourceToken: this.cardToken,
      email: this.email,
      is_apple_pay: this.paymentMethod === PaymentMethod?.APPLE_PAY ? 1 : 0
    }).subscribe((res: any) => {
      console.log("res", res);
      if(res?.error) {
        window.alert(res?.error);
      } else {
        this.onAcknowledgement();
      }
    })
  }

  onReload () {
    this.tripService.refetchSubject.next({silent: false});
    this.dialogRef.close();
  }


}
