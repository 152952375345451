import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  
  constructor(private readonly config: DynamicDialogConfig, private readonly dialogRef: DynamicDialogRef) {}

  
  onClick () {
    this.dialogRef.close(true);
  }
}
