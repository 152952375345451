import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { TripService } from 'src/app/services/trip/trip.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-extend-time-dialog',
  templateUrl: './extend-time-dialog.component.html',
  styleUrls: ['./extend-time-dialog.component.scss']
})
export class ExtendTimeDialogComponent implements OnInit {

  timeLeft!: number;
  isLoading: boolean = false;
  extended: boolean = false;
  constructor(
    private readonly config: DynamicDialogConfig, 
    public readonly dialogRef: DynamicDialogRef, 
    private utils: UtilsService, 
    private paymentService: PaymentService,
    private tripService: TripService) {
    this.timeLeft = config?.data?.timeLeft
  }

  ngOnInit(): void {
    this.utils.timerSubject.subscribe((time) => {
      if(time == 0) {
        this.dialogRef.close();
      }
      this.timeLeft = time;
    })
  }

  extendTime () {
    this.isLoading = true;
    this.paymentService.extend_payment_time().subscribe((res: any) => {
      if(res?.error) {
        window.alert(res?.error)
        this.dialogRef.close();
      } else {
        this.extended = true;
        this.config.header = 'Success! Session Extended';
        this.tripService.refetchSubject.next({silent: true});
      }
    }).add(() => {
      this.isLoading = false;
    });
  }

}
