<div class="p-4 bg-light">

    <div class="flex justify-content-between align-items-center">
        <div>
            <div class="text-gray text-xs">Car Service Co</div>
            <div class="text-dark-blue text-sm font-light">
                {{ carInfo.name }}
                <br>
                {{ carInfo.mobile }}
            </div>
        </div>
        <div>
            <img class="cursor-pointer" src="assets/images/phone.svg" alt="call_car_info" (click)="openDialog(carInfo.mobile)">
        </div>
    </div>

    <div class="flex justify-content-between align-items-center mt-3">
        <div>
            <div class="text-gray text-xs">Driver Details</div>
            <div class="text-dark-blue text-sm font-light">
                {{ driverInfo.name }}
                <br>
                {{ driverInfo.mobile }}
            </div>
        </div>
        <div>
            <img class="cursor-pointer" src="assets/images/phone.svg" alt="driver_info" (click)="openDialog(driverInfo.mobile)">
        </div>
    </div>
</div>

<div class="flex justify-content-center py-4 p-dialog-footer text-sm" (click)="dialogRef.close()">
    <div class="text-gray cursor-pointer"> Dismiss </div>
</div>