import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-splash-loading',
  templateUrl: './splash-loading.component.html',
  styleUrls: ['./splash-loading.component.scss']
})
export class SplashLoadingComponent {
  @Input() active: boolean = false;
}
