<div>
    <div class="flex justify-content-end w-screen">
        <i class="pi pi-times text-xl p-4 cursor-pointer" (click)="dialogRef.close()"></i>
    </div>

    <div class="menu-container absolute w-screen">
        <div *ngFor="let item of menuItems" class="text-center mb-3 cursor-pointer" (click)="onNavigate(item)">
            {{ item.title }}
        </div>
    </div>
</div>