import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-call-info-dialog',
  templateUrl: './call-info-dialog.component.html',
  styleUrls: ['./call-info-dialog.component.scss']
})
export class CallInfoDialogComponent implements OnInit {
  
  carInfo!: {
    name: string;
    mobile: string;
  };
  driverInfo!: {
    name: string;
    mobile: string;
  };

  constructor(private readonly config: DynamicDialogConfig, public readonly dialogRef: DynamicDialogRef) {}

  ngOnInit(): void {
    this.carInfo = this.config?.data?.carInfo;
    this.driverInfo = this.config?.data?.driverInfo;
  }

  openDialog (mobile: string) {
    const telLink = `tel:${mobile}`;
    window.open(telLink, '_self');
  }
  
}
